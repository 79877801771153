import PageTitle from '@components/PageTitle';
import { Typography } from '@mui/material';
import { Button, Card, CardBody, CardHeader, Chip, Link } from '@nextui-org/react';
import { handleViewCaregiverClick } from '@pages/Agencies/index';
import { getAgencyDetails } from '@services/agencies';
import type { AgencyDetailsObject } from '@services/agencies/types';
import { formatDate } from '@utils/date';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { handleViewAdminClick } from '..';

const AgencyDetails = () => {
  const [agency, setAgency] = useState<AgencyDetailsObject>();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  const getAgency = async () => {
    if (!id) return;

    const agencyResponse = await getAgencyDetails(id);

    if (agencyResponse) setAgency(agencyResponse);
  };

  useEffect(() => {
    getAgency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-between">
        <PageTitle>Agency - {agency?.name}</PageTitle>
        <Button
          onClick={() => navigate(`/agencies/${id}/sms-manager`)}
          style={{
            background: '#6558F5',
            color: 'white',
          }}
        >
          Manage SMS
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-8">
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Launch date</span>
          </CardHeader>
          <CardBody>{formatDate(agency?.launchDate || '')}</CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Timezone</span>
          </CardHeader>
          <CardBody>
            <span className="text-base">{agency?.timezone}</span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Point name</span>
          </CardHeader>
          <CardBody>
            <span className="text-base">{agency?.pointName}</span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Point name</span>
          </CardHeader>
          <CardBody>
            <span className="text-base">{agency?.pointName}</span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Points</span>
          </CardHeader>
          <CardBody className="gap-y-2">
            <span className="flex justify-between text-base">
              Treasure box price
              <Chip color="secondary">{agency?.treasureBoxPrice || 0}</Chip>
            </span>
            <span className="flex justify-between text-base">
              Base giftcard price <Chip color="secondary">{agency?.baseGiftcardPrice || 0}</Chip>
            </span>
            <span className="flex justify-between text-base">
              Click in streak points{' '}
              <Chip color="secondary">{agency?.clickInStreakPoints || 0}</Chip>
            </span>
            <span className="flex justify-between text-base">
              Weekly hours streak points{' '}
              <Chip color="secondary">{agency?.weeklyHoursStreakPoints || 0}</Chip>
            </span>
            <span className="flex justify-between text-base">
              Acceptable delay in minutes{' '}
              <Chip color="secondary">{agency?.acceptableDelayInMinutes || 0}</Chip>
            </span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Links</span>
          </CardHeader>
          <CardBody className="gap-2">
            <span className="flex text-base">
              <Typography
                className="cursor-pointer underline"
                onClick={() => agency && handleViewCaregiverClick(agency.id)}
              >
                APP
              </Typography>
            </span>
            <span className="text-base">
              <Typography
                className="cursor-pointer underline"
                onClick={() => agency && handleViewAdminClick(agency.id)}
              >
                DASHBOARD
              </Typography>
            </span>
            <span className="text-base">
              <Link showAnchorIcon className="cursor-pointer" href={agency?.engagementSurveyUrl}>
                ENGAGEMENT SURVEY
              </Link>
            </span>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="ml-2">
            <span className="text-lg font-bold">Data provider</span>
          </CardHeader>
          <CardBody>
            <span className="text-base">{agency?.dataProvider}</span>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AgencyDetails;
